import UnityGame from '../../components/UnityGame';

function TicTacToe() {
  return (
    <UnityGame
      loaderUrl="/retro/tictactoe/TicTacToe.loader.js"
      dataUrl="/retro/tictactoe/TicTacToe.data"
      frameworkUrl="/retro/tictactoe/TicTacToe.framework.js"
      codeUrl="/retro/tictactoe/TicTacToe.wasm"
    />
  );
}

export default TicTacToe;