import Collapsable from "../components/Collapsable";

function WorkExperience() {
    return (
        <div>
            <h2 id="work-experience">🔧 Work Experience 🔧</h2>
            <p>
                Backend Engineer
                <br/>Kotlin - Java - Python - JavaScript
                <br/>AWS
            </p>

            <Collapsable title="Amazon" subtitle="2019 - present">
                <p>
                    Rebuilt the service used to store the hours worked by employees which are used to generate reports. Built the new service using an event-driven architecture to support large volumes of data and batch the processing to improve processing time.

                    <br/><br/>
                    Worked on the development of the internal web application to perform product recalls for the product compliance.
                    I developed a system to monitor the Amazon catalog updates to ensure that recalled products remained suppressed even when other actors modified the product status.
                    I also improved our process to suppress a product from the retail website by combining the multiple workflows into a single workflow, improving the completion time and reducing the amount of resources needed to perform a recall operation.
                </p>
            </Collapsable>

            <Collapsable title="Wizeline" subtitle="2017 - 2019">
                <p>
                    Developed the backend services for <a href='https://www.fitzframes.com/' target="_blank" rel="noreferrer">Fitz Frames</a>, a mobile application that sells custom made eyeglasses.
                    I worked on the design and development of the API, on the payment system integrated with Stripe and developed the event system that triggers the whole workflow to create and fulfill an order.              

                    <br/><br/>
                    Worked on the backend of a web application to create and manage roadmaps for project management.

                    <br/><br/>
                    Developed REST API microservices to replace legacy services from Dow Jones.
                    To improve the development cycle I developed a shared library of functionalities that was used in all the new microservices.
                    One of its main features was the serialization and deserialization of Java objects to the JSON API specification.
                </p>
            </Collapsable>

            <Collapsable title="Oracle" subtitle="2014 - 2017">
                <p>
                    Worked for the Release Management team building features on the Product and Release Application, a web application used to manage the new products and release versions of products owned by Oracle.
                </p>
            </Collapsable>

        </div>
    );
}

export default WorkExperience;