import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import RetroGallery from './pages/RetroGallery';
import TicTacToe from './pages/retro-games/TicTacToe';
import Breakout from './pages/retro-games/Breakout';
import NotFound from './pages/NotFound';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/retro" element={<RetroGallery />} />
          <Route path="/retro/tictactoe" element={<TicTacToe />} />
          <Route path="/retro/breakout" element={<Breakout />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/skippydog/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={NotFound} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
