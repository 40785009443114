import UnityGame from '../../components/UnityGame';

function Breakout() {
  return (
    <UnityGame
      loaderUrl="/retro/breakout/Breakout.loader.js"
      dataUrl="/retro/breakout/Breakout.data"
      frameworkUrl="/retro/breakout/Breakout.framework.js"
      codeUrl="/retro/breakout/Breakout.wasm"
    />
  );
}

export default Breakout;