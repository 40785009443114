import { Link } from 'react-router-dom';
import tictactoe from '../images/thumbnails/tictactoe-thumbnail.png';
import breakout from '../images/thumbnails/breakout-thumbnail.png';
import GridItem from '../components/GridItem';
import './RetroGallery.css';

function RetroGallery() {
    return (
        <div className="retroGallery">
            <Link to="/" className="goBack">Go back to home page</Link>
            <div className="retroGallery-body">
                <h1>Retro Gallery</h1>
                <h2>This is a collection of classic retro games that I am recreating to practice my game dev skills.</h2>

                <div className="grid-container">
                    <GridItem image={tictactoe} route="/retro/tictactoe">Tic Tac Toe</GridItem>
                    <GridItem image={breakout} route="/retro/breakout">Breakout</GridItem>
                    {/* <GridItem image={skippyThumbnail} route="/retro/tictactoe">Snake</GridItem> */}
                </div>
            </div>

            
        </div>
    )
}

export default RetroGallery;