import AboutMe from '../sections/AboutMe';
import SocialMedia from '../sections/SocialMedia';
import Contact from '../sections/Contact';
import Education from '../sections/Education';
import WorkExperience from '../sections/WorkExperience';
import Games from '../sections/Games';
import './Home.css';
import React, { useState } from 'react';

function Home() {
    const [currentBackgroundColor, setCurrentBackgroundColor] = useState(getRandomBackgroundColor());

    return ( 
        <div className="Home" style={{backgroundColor: currentBackgroundColor}}>
            <header>
                <div className="refresh-color">
                    <button 
                        style={{
                            background: 'none',
                            color: 'white',
                            textDecoration: 'underline',
                            border: 'none',
                            cursor: 'pointer',
                            padding: '0',
                            font: 'inherit'
                        }}
                        onClick={changeBackgrounColor}
                    >Change background color</button>
                </div>
            </header>
            <div className="Home-body">
                <div>
                    <AboutMe/>
                    <hr/>
                    <WorkExperience/>
                    <hr/>
                    <Education/>
                    <hr/>
                    <Games/>
                    <hr/>
                    <Contact/>
                    <SocialMedia/>
                    <hr/>
                </div>
            </div>            
        </div>
    );

    function changeBackgrounColor() {
        setCurrentBackgroundColor(previousColor => getRandomBackgroundColor(previousColor));
    }

    function getRandomBackgroundColor(currentValue) {
        const backgrounds = [
            "#973535", // Red
            "#193C64", // Blue
            "#10233C", // Dark Blue
            "#17824F", // Green
            "#000000", // Black
            "#781E4b", // Magenta
            "#212121", // Dark Grey
            "#005A5A", // Aqua
        ];

        let index;
        do {
            index = Math.floor(Math.random() * backgrounds.length);
        } while (currentValue === backgrounds[index]);
        return backgrounds[index];
    }
} 
export default Home; 