import skippyThumbnail from '../images/skippy-thumbnail.png';
import appStoreIcon from '../images/app-store.png';
import playStoreIcon from '../images/google-play.png';
import itchioIcon from '../images/itchio.png';
import { Link } from 'react-router-dom';
import './Games.css';

function Games() {
    return (
        <div>
            <h2 id="game-development">🕹️Game Development🕹️</h2>
            <p>
                <h4 style={{ marginBottom: '0px' }}>Co-Creator & Programmer of SKIPPY DOG</h4>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{ display: 'flex', marginTop:"16px", alignItems: 'center' }}>
                        <img className='GameImage' alt="" src={skippyThumbnail}/>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: '1', justifyContent: 'center' }}>
                            <a href="https://apps.apple.com/us/app/skippy-dog/id6474350496" target="_blank" rel="noreferrer" style={{ marginBottom: '15px' }}>
                                <img src={appStoreIcon} alt="" style={{ maxWidth: '85%' }} />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.LaResistanceGames.SkippyDog" target="_blank" rel="noreferrer" style={{ marginBottom: '15px' }}>
                                <img src={playStoreIcon} alt="" style={{ maxWidth: '85%' }} />
                            </a>
                            <a href="https://laresistancegames.itch.io/skippydog" target="_blank" rel="noreferrer">
                                <img src={itchioIcon} alt="" style={{ maxWidth: '85%' }} />
                            </a>
                        </div>
                    </div>
                </div>
            </p>

            <div>
                <h4 style={{ marginBottom: '10px' }}>Retro Games</h4>
                <div>Play retro games remade by me <Link to="/retro">here</Link></div>
            </div>
        </div>
    )
}

export default Games;