function Education() {
    return (
        <div>
            <h2 id="education">🏫 Education 🏫</h2>
            <p>
                Bachelor degree in Computer Systems Engineering
                <br/>ITESO (August 2009 - December 2013)
            </p>
        </div>
    );
}

export default Education;