import { Component } from 'react';
import './Collapsable.css';

class Collapsable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle(_event) {
        this.setState({
            open: !this.state.open,
        });
    };

    render() {
        return (
            <div>
                <div className="clickable" onClick={(e) => this.toggle(e)}>
                    <h3 className="title">
                        {this.props.title}
                    </h3>
                    
                    <div className="subtitle">{this.props.subtitle}</div>
                </div>
                {
                    this.state.open ?
                    <div>
                        <div
                            className='clickMore'
                            onClick={(e) => this.toggle(e)}
                        >
                            <i>Click to minimize</i>
                        </div>
                        {this.props.children}
                    </div> :
                    <div
                        className='clickMore'
                        onClick={(e) => this.toggle(e)}
                    >
                        <i>Click to learn more</i>
                    </div>
                }
                
            </div>
        );
    }
}

export default Collapsable;