function Contact() {
    return (
        <div>
            <h2 id="contact">✉️ Contact ✉️</h2>
            <p>
                Please contact me at: ivanchezg@gmail.com
            </p>
        </div>
    );
}

export default Contact;