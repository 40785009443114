import './GridItem.css';

function GridItem({ image, route, children }) {
    return (
        <div className="grid-item">
            <a href={route}>
                <h1 style={{color: 'white', marginTop: 0, marginBottom: '6px'}}>{children}</h1>
                <img src={image} alt="" style={{ width: '480px', height: '300px'}}/>
            </a>
        </div>
        
        
    );
}

export default GridItem;