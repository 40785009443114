import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

function UnityGame({ loaderUrl, dataUrl, frameworkUrl, codeUrl}) {
  const { unityProvider } = useUnityContext({
    loaderUrl: loaderUrl,
    dataUrl: dataUrl,
    frameworkUrl: frameworkUrl,
    codeUrl: codeUrl,
  });

  return (
    <div>
      <div style={{
        position: 'absolute',
        top: '0',
        left: '0',
        marginLeft: '50px',
        marginTop: '30px',
        color: 'white',
      }}>
        <a href="/retro" style={{ color: 'white', fontFamily: 'Tiny5, sans-serif', fontSize: 'calc(10px + 1.5vmin)' }}>Go back to Gallery</a>
      </div>
      <div style={{ backgroundColor: 'black', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
          <Unity
              unityProvider={unityProvider}
              style={{ width: 960, height: 600 }}
          />
      </div>
    </div>
  );
}

export default UnityGame;